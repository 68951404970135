body {
    font-family: "Montserrat", sans-serif;
    font-size: 12pt;
}

ul {
    list-style-type: disc;
    padding: 0 0 0 1.2em;
}

.table {
    width: 100%;
    margin: 0.5em 0 !important;
}

.legend {
    margin: 1em 0 0;
    font-size: 0.8em;
}

.inactive {
    display: none;
}

.bcLink a {
    text-decoration: underline;
    color: #4a4a4a;
    font-size: 0.9em;
}

.notification.is-warning {
    margin: 0 1.8em;
}

/* Using important to override inline styles given by library used for flag icons */
.countryFlag {
    height: auto !important;
}


/*==============================================================================
 Header
==============================================================================*/

.navbar {
    background-color: #e5e5e5;
    padding: 0.6em;
}

.navbar-brand {
    justify-content: space-between;
}

.navbar .icon {
    color: #666;
}


/*==============================================================================
 Form
==============================================================================*/

.field {
    padding: 0.6em 1.8em 0;
}

.label {
    font-weight: 500;
}


.label.is-large {
    font-size: 1.2rem;
}

.button.is-info {
    background-color: #1a786c;
    border-radius: 2px;
    -webkit-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
}

.button.is-info:hover {
    background-color: #489553;
}

.filterSearch .button {
    margin-right: 5px;
}

.react-autosuggest__suggestions-container > ul {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    list-style-type: none;
    font-size: .8rem;
    padding: 0.8em;
}

.react-autosuggest__suggestions-container > ul > li {
    padding: 0.5em 0;
    border-bottom: 1px solid #dbdbdb;
}

.react-autosuggest__suggestions-container > ul > li:last-child {
    border-bottom: 0;
}

.buttonLink a {
    color: #363636;
    font-weight: 400;
}

/*==============================================================================
 Trips List
==============================================================================*/

.tripsListTitle {
    padding: 0.5em;
    margin-bottom: 1rem !important;
}

.tripsListTitle button {
    float: right;
}

.dateRange {
    text-align: center;
}

.dateRange .icon {
    font-size: 25px;
    padding: 0 0.8em;
}

.dateRange .date {
    min-width: 220px;
    display: inline-block;
}

.tripBlock {
    margin: 1em 1.2em 0;
    text-align: left;
}

.tripBlockStatus {
    background-color: #f4f4f4;
    padding: 0.3em 1.6em;
    font-size: 0.8rem;
    display: block;
}

.tripBlockContent {
    background-color: #1a786c;
    padding: 1.2em 1.6em 1.6em 1.6em;
    color: #fff;
    position: relative;
}

.readyIconHolder {
    position: absolute;
    right: 10px;
    bottom: 12px;
    width: 22px;
    height: 22px;
}

.ski_snowboard .tripBlockContent {
    background-color: #4d9bb7;
}

.multi_activity .tripBlockContent {
    background-color: #E67022;
}

.room_board .tripBlockContent {
    background-color: #653543;
}

.tripBlock h3 {
    color: #fff;
    font-size: 17pt;
    margin-bottom: 0.2em;
    font-weight: 300;
}

.content.tripBlock p {
    margin-bottom: 0.8em;
    font-style: italic;
}

.content.tripBlock .place {
    margin-bottom: 0;
    font-style: normal;
    font-size: 0.8em;
}


/*==============================================================================
 Trip
==============================================================================*/

.page-title {
    padding: 0.5em 1.2em;
    margin: 0;
    font-weight: 600;
}

.tabMenuWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tabMenu {
    border-right: 2px solid #fff;
    text-align: center;
    padding: 0.4em 0 0;
    display: inline-block;
    flex-grow: 1;
    border-bottom: 2px solid #fff;
}

.tabMenu:last-child {
    border-right: 0;
}

.tabMenu.tripIcon {
    background-color: #1a786c;
}

.tabMenu.dietIcon {
    background-color: #489553;
}

.tabMenu.peopleIcon {
    background-color: #874b7b;
}

.tabMenu.accommIcon {
    background-color: #a74462;
}

.tabMenu.medicalIcon {
    background-color: #0f9da5;
}

.tabMenu.transportIcon {
    background-color: #32738d;
}

.active.tabMenu.tripIcon {
    border-bottom: 2px solid #1a786c;
}

.active.tabMenu.dietIcon {
    border-bottom: 2px solid #489553;
}

.active.tabMenu.peopleIcon {
    border-bottom: 2px solid #874b7b;
}

.active.tabMenu.accommIcon {
    border-bottom: 2px solid #a74462;
}

.active.tabMenu.medicalIcon {
    border-bottom: 2px solid #0f9da5;
}

.active.tabMenu.transportIcon {
    border-bottom: 2px solid #32738d;
}

.Collapsible {
    margin: 0.5em 0;
}

.Collapsible__trigger {
    background-color: #1a786c;
    border: 0;
    color: #fff;
    padding: 0.8em 2em 0.8em 1em;
    font-size: 0.9em;
    display: block;
    position: relative;
}

.Collapsible__trigger.is-closed:after {
    font-family: 'Font Awesome 5 Duotone', serif;
    content: '\10F055'; /* fad fa-plus-circle */
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
}

.Collapsible__trigger.is-open:after {
    font-family: 'Font Awesome 5 Duotone', serif;
    content: '\10F056'; /* fad fa-minus-circle */
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
}

.Collapsible__contentInner {
    padding: 0.5em 0 1.4em;
    border: 0;
}

.titleBetweenSection {
    border-top: 1px solid #e5e5e5;
    margin: 1.8em 0 1em;
    font-weight: 600;
    padding: 0.5em 0 0;
}

.titleBetweenSection.noBorder {
    border: 0;
}

.sectionTitle {
    font-weight: 600;
    font-size: 1.2em;
    margin: 0 0 .4em;
    overflow: hidden;
}

.sectionTitle .button {
    float: right;
}

.dietaryBlock,
.medicalBlock {
    margin-bottom: 1.5em;
}

.blockHeader {
    background: #e5e5e5;
    padding: 0.4em 0.6em;
    margin-bottom: 0.2em;
    font-size: 0.9em;
}

.medicalNotes {
    font-style: italic;
    font-size: 0.9em;
}

/*--------------------------------------
 Trip Overview
--------------------------------------*/

.tripOverview {
    border-top: 20px solid #1a786c;
    padding: 1em 1.2em;
}

.tripOverview .Collapsible__trigger {
    background-color: #1a786c;
}

.resortListItem {
    margin-bottom: 0.5em;
}

.tripNotes .title {
    margin: 0.8em 0 0.2em;
}

.tripOverview .table td:first-child,
.tripOverview .table th:first-child {
    padding-left: 0;
}

.tripOverview .table td:last-child,
.tripOverview .table th:last-child {
    padding-right: 0;
}

/*--------------------------------------
 PeopleOverview
--------------------------------------*/

.peopleOverview {
    border-top: 20px solid #874b7b;
    padding: 1em 1.2em;
}

.peopleOverview .Collapsible__trigger {
    background-color: #874b7b;
}

.staffBlock, .participantBlock {
    width: 100%;
    margin-bottom: 3px;
    line-height: 20px;
    margin-left: 0;
    background-color: #e5e5e5;
    font-size: .8rem;
    padding: 0.8em;
}

.skiLegend, .boardLegend {
    margin: 1em 2em 0.4em 0;
    display: inline-block;
}

.skiLegend:before{
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background: #f3caa0;
    margin-right: 5px;
}

.boardLegend:before{
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    background: #f28e91;
    margin-right: 5px;
}

.participantBlock.Ski {
    background: #f3caa0;
}

.participantBlock.Board {
    background: #f28e91;
}

.participantBlock.Multi {
    background: #E67022;
}

.participantName {
    margin-left: 6px;
}

.participantDetailName {
    display: inline-block;
    color: #489553;
    margin: 0.8em 0;
    font-size: 1.5rem;
    font-weight: 600;
}

.participantGender {
    display: inline-block;
    float: left;
}

.genderIcon {
    border: 2px solid#4a4a4a;
    border-radius: 5px;
    padding: 5px;
}

.participantDetailsIcons {
    clear: both;
    padding: 1.2em 0;
}

.participantIcon {
    fill: #e5e5e5;
    border: 2px solid#4a4a4a;
    border-radius: 5px;
    height: 90px;
    width: 90px;
    margin-right: 7px
}

.participantAge {
    font-weight: 300 !important;
}

.participantDetails {
    float: left;
    font-size: .9rem;
    margin-left: 15px;
    color: #777;
    height: auto;
    line-height: 25px;
    display: inline-block;
}

.participantDetail {
    color: #424242;
    font-weight: bold;
}

/*--------------------------------------
 Medical Overview
--------------------------------------*/

.medicalOverview {
    border-top: 20px solid #0f9da5;
    padding: 1em 1.2em;
}

.medicalOverview .Collapsible__trigger {
    background-color: #0f9da5;
}

/*--------------------------------------
 Transport Overview
--------------------------------------*/

.transportOverview {
    border-top: 20px solid #32738d;
    padding: 1em 1.2em;
}

.transportOverview .Collapsible__trigger {
    background-color: #32738d;
}

/*--------------------------------------
 Accommodation Overview
--------------------------------------*/

.accommodationOverview {
    border-top: 20px solid #a74462;
    padding: 1em 1.2em;
}

.accommodationOverview .Collapsible__trigger {
    background-color: #a74462;
}

.resortBlock {
    margin: 1em 0;
    padding: 0.6em 0;
    border-bottom: 1px solid #e5e5e5;
}

/*--------------------------------------
 Dietary Overview
--------------------------------------*/

.dietaryOverview {
    border-top: 20px solid #489553;
    padding: 1em 1.2em;
}

.dietaryOverview .Collapsible__trigger {
    background-color: #489553;
}


/*==============================================================================
 Modal
==============================================================================*/

.searchModal {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 0.8em;
}

.modal-content {
    max-height: calc(100vh - 80px);
    padding: 0 2px;
}

.searchModal .field {
    padding: 0.6em 0 0;
    margin-bottom: 0.5rem;
}

.searchModal .label {
    margin-bottom: 0.2em;
}

.sortMethod {
    margin: 10px 0;
    padding: 5px 10px;
    background-color: #e5e5e5;
}

.sortMethod.active {
    background-color:#4a4a4a;
    color: white;
}

.DateInput_input {
    text-align: center;
}

.DayPicker table td:not([align]),
.DayPicker table th:not([align]) {
    text-align: center;
    vertical-align: middle;
}

.CalendarMonth_caption {
    padding-top: 18px !important;
}

.CalendarMonth select {
    border: 1px solid #e4e7e7;
    min-height: 33px;
}

.control.has-icons-right .input {
    padding-right: 0.5em;
}

.field.is-grouped > .control:not(:last-child) {
    margin-right: 0.50rem;
}

.select:not(.is-multiple):not(.is-loading)::after {
    border-color: #363636;
    right: 0.7em;
}

.select select:not([multiple]) {
    padding-right: 1.8em;
}
